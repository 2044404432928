import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = ["src", "title"]
const _hoisted_3 = { class: "max-w-72" }
const _hoisted_4 = { class: "flex flex-row truncate items-center" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "flex flex-row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelWithColon = _resolveComponent("LabelWithColon")!
  const _component_CopyIcon = _resolveComponent("CopyIcon")!
  const _component_Tippy = _resolveComponent("Tippy")!
  const _component_ModalTemplate = _resolveComponent("ModalTemplate")!

  return (_openBlock(), _createBlock(_component_ModalTemplate, {
    "modal-id": _ctx.modalId,
    title: "Detail Materi"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_LabelWithColon, {
          label: "Title",
          value: _ctx.detailMateri.title,
          loading: _ctx.loading,
          margin: "mb-2"
        }, null, 8, ["value", "loading"]),
        _createVNode(_component_LabelWithColon, {
          label: "Banner",
          "non-text": "",
          loading: _ctx.loading,
          margin: "my-2"
        }, {
          content: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.detailMateri.banner,
              alt: "banner-materi",
              class: "max-w-72",
              title: 'Banner ' + _ctx.detailMateri.title,
              "data-action": "zoom"
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_LabelWithColon, {
          label: "Tipe",
          value: _ctx.detailMateri.types === 'ebook' ? 'e-Book' : 'Video',
          loading: _ctx.loading,
          margin: "mb-2"
        }, null, 8, ["value", "loading"]),
        _createVNode(_component_LabelWithColon, {
          label: "Content",
          "non-text": "",
          loading: _ctx.loading,
          margin: "my-2"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Tippy, {
                  tag: "a",
                  href: "javascript:;",
                  class: "inline-block mr-2",
                  content: "Copy Link",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gpf.copy(_ctx.detailMateri.content)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CopyIcon, { class: "w-6 h-6 cursor-pointer text-primary" })
                  ]),
                  _: 1
                }),
                _createElementVNode("a", {
                  href: _ctx.detailMateri.content,
                  target: "_blank",
                  class: "truncate"
                }, _toDisplayString(_ctx.detailMateri.content), 9, _hoisted_5)
              ])
            ])
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_LabelWithColon, {
          label: "Kategori",
          value: _ctx.categoryTitle,
          loading: _ctx.loading,
          margin: "mb-2"
        }, null, 8, ["value", "loading"]),
        _createVNode(_component_LabelWithColon, {
          label: "Topik",
          value: _ctx.topicTitle,
          loading: _ctx.loading,
          margin: "mb-2"
        }, null, 8, ["value", "loading"]),
        _createVNode(_component_LabelWithColon, {
          label: "Status",
          "non-text": "",
          loading: _ctx.loading
        }, {
          content: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass([
                _ctx.detailMateri.isActive ? 'text-green-600' : 'text-yellow-500',
              ])
            }, _toDisplayString(_ctx.detailMateri.isActive ? "Publish" : "Draft"), 3)
          ]),
          _: 1
        }, 8, ["loading"])
      ])
    ]),
    buttons: _withCtx(() => [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          type: "button",
          class: "button w-20 bg-theme-6 text-white ml-auto",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onCloseClick && _ctx.onCloseClick(...args)))
        }, " Tutup ")
      ])
    ]),
    _: 1
  }, 8, ["modal-id"]))
}