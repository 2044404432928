
import { MateriDetailType } from "@/services/v1/admin/learning/types";
import { gpf } from "@/utils/global-functions";
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  nextTick,
  ref,
  watch,
} from "vue";

const ModalTemplate = defineAsyncComponent(
  () => import(`@/components/modal-template/Main.vue`)
);

const LabelWithColon = defineAsyncComponent(
  () => import(`@/components/label/WithColon.vue`)
);

const servicesV1AdminLearning = async () =>
  import(`@/services/v1/admin/learning`);

export default defineComponent({
  components: {
    ModalTemplate,
    LabelWithColon,
  },
  props: {
    showDetailMateri: {
      type: Boolean,
      default: false,
    },
    materiId: {
      type: String,
      default: "",
    },
    jenisMateri: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const modalId = "modal-detail-materi";
    const detailMateri = ref({} as MateriDetailType);
    const loading = ref(false as boolean);

    const getDetailMateri = async () => {
      const adminLearningId = props.materiId;
      if (!adminLearningId) return;

      const { getAdminLearningById } = await servicesV1AdminLearning();

      gpf.gLoading.show();
      loading.value = true;

      try {
        const hit = await getAdminLearningById(adminLearningId);
        const resData = hit.response;

        console.log("resData getDetailMateri ", resData);

        await nextTick();

        detailMateri.value = resData;
      } catch (err: any) {
        console.log("err response getDetailMateri ", err, err?.response);

        gpf.handleEds(err);
      } finally {
        gpf.gLoading.hide();
        loading.value = false;
      }
    };

    const categoryTitle = computed(
      () => detailMateri.value?.category?.title || "-"
    );

    const topicTitle = computed(() => detailMateri.value?.topic?.title || "-");

    const hideDetailMateri = () => {
      // detailMateri.value = {};

      emit("update:show-detail-materi", false);
      emit("update:materi-id", 0);
    };

    watch(
      () => props.showDetailMateri,
      (newVal) => {
        if (newVal) gpf.showModal(`#${modalId}`);
        gpf.onHideModal(`#${modalId}`, hideDetailMateri);
        getDetailMateri();
      }
    );

    const onCloseClick = () => {
      gpf.hideModal(`#${modalId}`);
    };

    return {
      modalId,
      detailMateri,
      gpf,
      loading,
      categoryTitle,
      topicTitle,
      onCloseClick,
    };
  },
});
